import React, {useState} from 'react';
import { HashRouter as Router, useLocation } from "react-router-dom";
import InfoWidget from './InfoWidget';
import MyCollections from './MyCollections';

const Main = ({collectionsInfoWidgetPath, publishNewCollectionPath, myCollectionsPath, checkPendingStatusPath, publishedCollectionCount, currentUserId, collectionApplicationPath, isQualifiedForCollectionApplication}) => {

  const renderCollectionContent = () => {
    if (isQualifiedForCollectionApplication) {
      return (
        <div className="large-9 medium-12 small-12 columns new-dashboard-section">
          <div className='collection-container'>
            <div className='collection-card-container'>
              <div className='large-3 columns create-new-collection-card'>
                <a className='add-button' href={collectionApplicationPath}>
                  <img 
                    className='add-button-plus' 
                    src="https://public.cureus.com/my_dashboard/my_collections/add-button-plus.png"
                  />
                </a>
                <h5>New Collection Application</h5>
                <p>Click above to submit your proposal for editorial review.</p>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <MyCollections 
          publishNewCollectionPath={publishNewCollectionPath}
          myCollectionsPath={myCollectionsPath}
          checkPendingStatusPath={checkPendingStatusPath}
          publishedCollectionCount={publishedCollectionCount}
        />
      )
    }
  }

  return (
    <React.Fragment>
      <Router hashType={'hashbang'}>
        <InfoWidget 
          collectionsInfoWidgetPath={collectionsInfoWidgetPath}
          currentUserId={currentUserId}
          isQualifiedForCollectionApplication={isQualifiedForCollectionApplication}
        />
        {renderCollectionContent()}
      </Router>
    </React.Fragment>
  )
}

export default Main;