import React from 'react';

const InfoWidgetCollectionApplication = () => {
  return (
    <div className='start-container'>
      <div className='start-img-container'>
        <img className='start-img' src="https://public.cureus.com/my_dashboard/my_collections/start-image.png" />
      </div>
      <h6>JOIN THE TEAM!</h6>
      <h1>Cureus Collections</h1>
      <p>Would you like to serve as a guest editor for a collection of peer-reviewed articles on a specific topic? Please complete and submit a proposal form for consideration. We recommend including as many details as possible. Our editorial department will review your eligibility and the proposed topic before replying via email.</p>
      <div className='readmore-text-container'>
        <a href="/guest_editor_guide#!/overview/introduction">
          Read More
          <img className='right-arrow-img' src="https://public.cureus.com/my_dashboard/my_collections/black-arrow-right.png" alt="" />
        </a>
      </div>
    </div>
  )
}

export default InfoWidgetCollectionApplication;