import React from 'react';

const InfoWidgetStart = () => {
  return (
    <div className='start-container'>
      <div className='start-img-container'>
        <img className='start-img' src="https://public.cureus.com/my_dashboard/my_collections/start-image.png" />
      </div>
      <h6>JOIN THE TEAM!</h6>
      <h1>Cureus Collections</h1>
      <p>Good news - your collection proposal has been accepted! Click the New Collection button to start building your collection. Remember to adhere to the proposal that you submitted. When you’re ready, submit your completed collection for editorial review and we’ll review it as soon as possible.</p>
      <div className='readmore-text-container'>
        <a href="/guest_editor_guide#!/overview/introduction">
          Read More
          <img className='right-arrow-img' src="https://public.cureus.com/my_dashboard/my_collections/black-arrow-right.png" alt="" />
        </a>
      </div>
    </div>
  )
}

export default InfoWidgetStart;